<template>
  <base-section
    id="k-d-a-project-detail-point-card"
    :class="!isOddNumber ? 'background-tech-list' : 'white'"
    space="80"
    top-inner-shadow
  >
    <v-container :class="`my-${g_bLowerBr ? 0 : 10} my-md-${g_bLowerBr ? 0 : 16}`">
      <v-row :class="`${g_bLowerBr ? 'px-3' : ''}`">
        <v-col cols="12" lg="5" md="5" style="flex-direction: column; display: flex">
          <div :class="`kda-ts-${g_bLowerBr ? 28 : 28}pt wt-extrabold mb-${g_bLowerBr ? 5 : 0}`">
            {{ strTitle }}
          </div>

          <div class="img-container">
            <img :src="imgConsult" :class="g_bLowerBr || g_bBaseBr ? 'top' : 'right'" />
          </div>

          <div class="mt-4 wrap-line-index" style="width: auto">
            <div class="line-index" />
            <div>0</div>
            <div>{{ numIndex + 1 }}</div>
          </div>
        </v-col>
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col
          cols="12"
          lg="6"
          md="6"
          style="
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div>
            <div :class="`kda-ts-${g_bLowerBr ? 28 : 40}pt wt-extrabold`">{{ strBold }}</div>
            <div :class="`kda-ts-${g_bLowerBr ? 12 : 20}pt nunito wt-regular mt-4 mt-md-4`">
              {{ strDescription }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAProjectDetailPointCard',

  mixins: [BaseBreakpoint],

  props: {
    strTitle: {
      type: String,
      default: ''
    },
    strBold: {
      type: String,
      default: ''
    },
    strConsult: {
      type: String,
      default: ''
    },
    strDescription: {
      type: String,
      default: ''
    },
    numIndex: {
      type: Number,
      default: 0
    },
    imgConsult: {
      type: String,
      default: ''
    }
  },

  computed: {
    isOddNumber() {
      return this.numIndex % 2 === 1;
    },
    isFirstIndex() {
      return this.numIndex === 0;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background-tech-list {
  background-color: #fbfbfb;
}

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.top {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
}

.line-index {
  position: relative;
  top: -10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #333333;
  width: 50%;
  margin-right: 10px;
}

.wrap-line-index {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
